.fr-view img {
  max-width: 100% !important;
}

.fr-view > *:not(ul) {
  width: 100% !important;
}

.fr-view pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: visible;
}

.st-inline-share-buttons {
  display: flex !important;
}

.st-total {
  width: 100%;
}

.st-btn {
  width: 100%;
}

.fr-box.fr-basic .fr-element.fr-view {
  font-family: "Roboto";
  font-size: 14px;
  padding: 8px 16px;
}

.fr-box {
  height: 100% !important;
}

.fr-wrapper {
  border-width: 0px !important;
  flex: 1 !important;
}

.fr-wrapper p {
  margin: 0;
  line-height: 1.5;
}

.fr-wrapper div {
  line-height: 1.5;
}

.fr-view blockquote {
  border-color: #c2c2c2 !important;
}

.fr-toolbar {
  border-width: 0px;
}

.special-label {
  margin-left: -15px;
}

.styled-tag-chip {
  background-color: #f8f8ff;
  border: 1px solid #bfbfff;
  border-radius: 4px;
  color: #2a2ac4;
  display: inline-block;
  margin: 0px;
  padding: 1px 8px;
  font-size: 11px;
  overflow-wrap: anywhere;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.styled-tag-button {
  background-color: #5555e7;
  border-radius: 8px;
  height: 36px;
  color: #ffffff;
  display: inline-block;
  margin: 0px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto;
  font-weight: 500;
  overflow-wrap: anywhere;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

.svg-safari-fix {
  height: inherit;
  width: inherit;
}

.grecaptcha-badge {
  display: none !important;
}

a {
  text-decoration-color: currentColor;
}

#card-pin-copy,
#card-cvc-copy,
#card-expiration-copy,
#card-number-copy {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  cursor: pointer;

  & > div {
    height: 100% !important;
  }
}

.fr-toolbar.fr-desktop > :nth-child(3) {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: wrap;
}

.notistack-SnackbarContainer {
  z-index: 120000 !important; /* This should be the highest z-index in the entire app */
}

.flatfile_iframe-wrapper {
  z-index: 100000 !important;
}

.flatfile_displayAsModal {
  padding: 10px !important;
  backdrop-filter: blur(8px);
  height: 100% !important;
}

.flatfile_displayAsModal iframe {
  height: 100% !important;
}

.flatfile-close-button {
  margin: 0 !important;
  top: 4px !important;
  right: 4px !important;
}
